import { MdDensityMedium } from "react-icons/md";
import { useContextTranslate } from "../Translate/ContextProvider";
import Logo from "../../images/Logo main.png";
import { NavLink } from "react-router-dom";
import { Select, Translation } from "../../components";
import PopUpUser from "./PopUpUser";
import { useClose } from "../../Tools/APIs";

const Navbar = () => {
  const { open, setOpen, mouse } = useClose();
  const { content } = useContextTranslate();
  return (
    <div
      ref={mouse}
      // style={{ backgroundColor: bgColor, transition: "0.3s" }}
      className={`sticky  z-[999999] top-0 left-0 w-full flex justify-between items-center py-3 px-3 bg-Main text-white`}
    >
      <div className="max-xl:ml-6 max-sm:mr-5 max-xl:order-2">
        <img src={Logo} className="w-14 xl:mx-8" alt="" />
      </div>
      <MdDensityMedium
        className="xl:hidden max-xl:mx-5 max-xl:order-1"
        size={25}
        onClick={() => setOpen(!open)}
      />
      <ul
        className={`justify-around items-center flex-1 xl:flex max-xl:order-1 ${
          open
            ? "flex-col absolute top-16 shadow-md rounded-3xl text-center overflow-hidden bg-Main xl:py-4 xl:px-5 max-xl:w-[200px]"
            : "hidden"
        }`}
      >
        <NavLink onClick={() => setOpen(false)} to="/home">
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.home}
          </li>
        </NavLink>

        <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
          <Select
            onClick={() => setOpen(false)}
            name={content.Buy}
            optionOne="Commercial"
            optionTwo="Residential"
            LinkOne={
              "buy?type=COMMERCIAL&local=" + localStorage.getItem("language")
            }
            LinkTwo={
              "buy?type=RESIDENTIAL&local=" + localStorage.getItem("language")
            }
          />
        </li>
        <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
          <Select
            onClick={() => setOpen(false)}
            name={content.NewProperty}
            optionOne="Commercial"
            optionTwo="Residential"
            LinkOne={
              "new-property?type=COMMERCIAL&new=1&local=" +
              localStorage.getItem("language")
            }
            LinkTwo={
              "new-property?type=RESIDENTIAL&new=1&local=" +
              localStorage.getItem("language")
            }
          />
        </li>

        <NavLink onClick={() => setOpen(false)} to="/sell">
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.Sell}
          </li>
        </NavLink>
        <NavLink
          onClick={() => setOpen(false)}
          to={
            "/income-property?income=1&local=" +
            localStorage.getItem("language")
          }
        >
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.IncomeProperty}
          </li>
        </NavLink>
        <NavLink onClick={() => setOpen(false)} to="/design">
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.Design}
          </li>
        </NavLink>
        <NavLink onClick={() => setOpen(false)} to="/blogs">
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.Blogs}
          </li>
        </NavLink>
        <NavLink onClick={() => setOpen(false)} to="/about-us">
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.aboutUs}
          </li>
        </NavLink>
        <NavLink onClick={() => setOpen(false)} to="/coming-soon-items">
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.Launches}
          </li>
        </NavLink>
        <NavLink onClick={() => setOpen(false)} to="/contact-us">
          <li className="max-xl:border-b-2 max-xl:hover:bg-Secondary max-xl:hover:text-white py-3">
            {content.ContactUs}
          </li>
        </NavLink>
      </ul>
      <div className="flex max-xl:order-3">
        <Translation />
        <PopUpUser />
      </div>
    </div>
  );
};

export default Navbar;
