import React, { createContext, useContext, useEffect, useState } from "react";
import Translate from "./translate.json";
import { useFETCH } from "../../Tools/APIs";

const ContextAPI = createContext({});
window.localStorage.getItem("language")
  ? window.localStorage.getItem("language")
  : window.localStorage.setItem("language", "en");
const ContextProvider = ({ children }) => {
  const [translat, setTranslat] = useState(
    window.localStorage.getItem("language")
  );
  const [content, setContent] = useState({});
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (window.localStorage.getItem("language") === "ar") {
      document.body.style.direction = "rtl";
      setContent(Translate.ar);
    }
    if (window.localStorage.getItem("language") === "en") {
      document.body.style.direction = "ltr";
      setContent(Translate.en);
    }
  }, [translat, content]);
  const changeLanguage2 = () => {
    setTranslat("ar");
    window.localStorage.setItem("language", "ar");
  };
  const changeLanguage = () => {
    setTranslat("en");
    window.localStorage.setItem("language", "en");
  };
  const { data: dataText, isLoading: LoadingText } = useFETCH(
    `info-text?local=${localStorage.getItem("language")}`
  );
  let dataTextAll = dataText?.data.data;
  return (
    <ContextAPI.Provider
      value={{
        content,
        changeLanguage,
        changeLanguage2,
        setMessage,
        message,
        dataTextAll,
        LoadingText,
      }}
    >
      {children}
    </ContextAPI.Provider>
  );
};
export default ContextProvider;

export const useContextTranslate = () => {
  return useContext(ContextAPI);
};
