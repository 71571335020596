import { Container, PrintHTML, SecondSlider } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
function BlogsDetails({ name }) {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `blogs/${id}?local=${localStorage.getItem("language")}`
  );
  console.log(data);
  return (
    <div>
      <SecondSlider name={name} secondName={data?.data.data.name} />
      <Container>
        <h2 className="text-3xl font-bold text-center">
          {content.FeaturedArticles}
        </h2>
        <h2 className="text-xl text-Main font-semibold py-3">
          {data?.data.data.name}
        </h2>
        <div className="my-5 font-semibold text-gray-400">
          {data?.data.data.date + " " + data?.data.data.time}
        </div>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="mySwiper h-[60vh] max-sm:max-h-[40vh] rounded-3xl"
          style={{ direction: "ltr" }}
        >
          {isLoading ? (
            <>
              <SwiperSlide className="">
                <div className="w-full h-[400px] rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[300px] rounded-2xl"></div>
                </div>
              </SwiperSlide>
            </>
          ) : (
            data?.data.data.images.map((e) => (
              <SwiperSlide className="">
                <img
                  src={fileUrl + e.image}
                  alt=""
                  className="rounded-[30px] !w-full"
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
        <div className="my-5" />
        <div>
          <PrintHTML text={data?.data.data.large_text} />
        </div>
      </Container>
    </div>
  );
}
export default BlogsDetails;
