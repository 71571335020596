import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Footer, Footer2, ContactSection, Message, SecondSlider } from "./components";
import {
  AboutUs,
  Area,
  Areas,
  Blogs,
  BlogsDetails,
  Code,
  Compound,
  Compounds,
  Design,
  Details,
  Favorite,
  ForgetPassword,
  Home,
  HowItWorks,
  Launches,
  LogIn,
  Property,
  Sell,
  SetPassword,
  SignUp,
  TermsOfUse,
} from "./pages/index";
import { useEffect } from "react";
import { RequireRoute, useFETCH } from "./Tools/APIs";
import { useContextTranslate } from "./components/Translate/ContextProvider";
import ContactTwo from "./components/Section/ContactTwo";
const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/") {
      navigate("/home");
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const { data } = useFETCH(
    localStorage.getItem("token") ? "users/profile" : ""
  );
  const { content } = useContextTranslate();
  return (
    <div
      className={localStorage.getItem("language") === "ar" ? "font-serif" : ""}
    >
      <Navbar />
      <Message />
      <Routes>
        <Route path="home">
          <Route index element={<Home />} />
          <Route path="compound/:id" element={<Compound />} />
          <Route path="area/:id" element={<Area />} />
        </Route>
        <Route path="search" element={<Property />} />
        <Route
          path="property/details/:id"
          element={<Details name={content.Compound} />}
        />
        <Route
          path="buy"
          element={<Property name={content.Buy} filters={true} />}
        />
        <Route
          path="new-property"
          element={
            <Property
              NewProperty="true"
              name={content.NewProperty}
              filters={true}
            />
          }
        />
        <Route
          path="income-property"
          element={<Property name={content.IncomeProperty} />}
        />
        <Route path="sell">
          <Route index element={<Sell name={content.Sell} />} />
        </Route>
        <Route path="design" element={<Design name={content.Design} />} />
        <Route path="about-us" element={<AboutUs name={content.aboutUs} />} />
        <Route path="favorite" element={<Favorite />} />
        <Route path="compounds" element={<Compounds />} />
        <Route path="areas" element={<Areas />} />
        <Route path="blogs">
          <Route index element={<Blogs name={content.Blogs} />} />
          <Route
            path="details/:id"
            element={<BlogsDetails name={content.Blogs} />}
          />
        </Route>
        <Route
          path="contact-us"
          element={
            <div>
              <SecondSlider name={content.ContactUs} />
              <h1 className="text-4xl text-center font-bold pt-4 mb-5">
                {content.ContactUs}
              </h1>
              <ContactTwo />
            </div>
          }
        />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="how-it-works" element={<HowItWorks />} />
        <Route
          path="coming-soon-items"
          element={<Launches name={content.Launches} />}
        />
        <Route path="log-in" element={<LogIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="verification-code" element={<Code />} />
        <Route path="set-password" element={<SetPassword />} />
        <Route path="forget-password" element={<ForgetPassword />} />
      </Routes>
      <Footer />
      <Footer2 />
    </div>
  );
};

export default App;
