import { Container, Input, SecondSlider, SelectTwo } from "../../components";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";

function Sell({ name }) {
  const { data } = useFETCH(
    `property-type?local=${localStorage.getItem("language")}`
  );
  const { error, handleChangeInput, loading, handleSubmit } = usePOST({});
  const mainSubmit = (e) => {
    handleSubmit("sellers", "/");
  };
  return (
    <div>
      <SecondSlider name={name} />
      {loading ? <Loading /> : ""}
      <Container>
        <Row justify="center">
          <Col md={4}>
            <div className="max-lg:w-full mx-auto h-full bg-gradient-to-t from-[#E00201] to-[#062371] p-[4px] rounded-3xl overflow-hidden my-4">
              <div className="mx-auto bg-gray-200 p-5 rounded-2xl border- bg-grad h-full">
                <div className="font-semibold text-Main text-center text-[18px] bg-white rounded-full w-fit mx-auto p-3">
                  <h1>1</h1>
                  <h1>الخطوة</h1>
                </div>
                <div className="font-semibold text-Main text-center text-[18px]">
                  قم بإدراج تفاصيل الوحدات الخاصة بك
                </div>
                <div className="font-semibold  text-center text-[15px]">
                  أضف جميع المعلومات المتعلقة بوحدتك
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="max-lg:w-full mx-auto h-full bg-gradient-to-t from-[#E00201] to-[#062371] p-[4px] rounded-3xl overflow-hidden my-4">
              <div className="mx-auto bg-gray-200 p-5 rounded-2xl border- bg-grad h-full">
                <div className="font-semibold text-Main text-center text-[18px] bg-white rounded-full w-fit mx-auto p-3">
                  <h1>2</h1>
                  <h1>الخطوة</h1>
                </div>

                <div className="font-semibold text-Main text-center text-[18px]">
                  سيتصل بك أحد وكلائنا
                </div>
                <div className="font-semibold  text-center text-[15px]">
                  سنساعدك في العثور على أفضل مشتري
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="max-lg:w-full mx-auto h-full bg-gradient-to-t from-[#E00201] to-[#062371] p-[4px] rounded-3xl overflow-hidden my-4">
              <div className="mx-auto bg-gray-200 p-5 rounded-2xl border- bg-grad h-full">
                <div className="font-semibold text-Main text-center text-[18px] bg-white rounded-full w-fit mx-auto p-3">
                  <h1>3</h1>
                  <h1>الخطوة</h1>
                </div>
                <div className="font-semibold text-Main text-center text-[18px]">
                  قابل المشترين الجادين
                </div>
                <div className="font-semibold  text-center text-[15px]">
                  الخطوة الأخيرة لبيع وحدتك
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <h2 className="text-center text-3xl text-gray-700 font-bold mt-6 mb-2 ">
          Sell Your Property With Us
        </h2>
        <div className="w-1/2 max-lg:w-full mx-auto bg-gradient-to-t from-[#E00201] to-[#062371] p-2 rounded-3xl overflow-hidden my-10">
          <div className="mx-auto bg-gray-200 p-5 rounded-2xl border- bg-grad">
            <Row>
              <Col xs={6}>
                <Input
                  onChange={handleChangeInput}
                  name="name"
                  title="Your Name"
                  className="bg-white"
                />
              </Col>
              <Col xs={6}>
                <Input
                  onChange={handleChangeInput}
                  name="location"
                  title="Location"
                  className="bg-white"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Input
                  onChange={handleChangeInput}
                  name="address"
                  title="Address"
                  className="bg-white"
                />
              </Col>
              <Col xs={6}>
                <Input
                  onChange={handleChangeInput}
                  name="phone_number"
                  title="Phone"
                  className="bg-white"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SelectTwo
                  data={data?.data.data}
                  onChange={handleChangeInput}
                  name="property_type_id"
                  title="Property Type"
                />
              </Col>
              <Col xs={6}></Col>
            </Row>
            <Row>
              <Col>
                <textarea
                  onChange={handleChangeInput}
                  name="description"
                  type="text"
                  placeholder="Cover letter"
                  className="bg-white text-gray-400 px-5 outline-none text-lg rounded-lg py-3 w-full block h-[150px]"
                />
              </Col>
            </Row>
            <div className="text-center text-red-500">{error}</div>
            <div
              onClick={mainSubmit}
              className="bg-Main rounded-lg text-center text-white text-xl font-semibold py-4 px-3 mt-4 transition-all hover:-translate-y-2 hover:bg-opacity-80 w-[70%] mx-auto"
            >
              Submit
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Sell;
