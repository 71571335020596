import { NavLink } from "react-router-dom";
import { Col, Row } from "../../Tools/Grid-system";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { LuInstagram } from "react-icons/lu";
import { useContextTranslate } from "../Translate/ContextProvider";
import Logo from "../../images/final logo-01 1.png";
import { useFETCH } from "../../Tools/APIs";
import image from "../../images/_2582886903680.png";

const Footer = () => {
  const { content } = useContextTranslate();
  const { data } = useFETCH(
    `areas?local=${localStorage.getItem("language")}&trending=1`
  );
  const { data: Recommended } = useFETCH(
    `properties?local=${window.localStorage.getItem("language")}&trending=1`
  );
  const { data: compounds } = useFETCH(
    `compounds?local=${localStorage.getItem("language")}&trending=1`
  );
  return (
    <footer className="relative bg-white py-3 border-t-4 shadow-md overflow-hidden">
      <div className="absolute bottom-0">
        <img alt="" src={image} className="w-2/3 left-0" />
      </div>
      <div className="absolute top-0 right-0 rotate-180">
        <img alt="" src={image} className="w-2/3" />
      </div>
      <Row justify="between" className="!my-20">
        <Col lg={3} md={0} className="justify-center">
          <ul className="flex flex-col justify-center items-center !mb-6">
            <img src={Logo} alt="" className="w-[250px]" />
            <div className="flex gap-5 mt-2">
              <LuInstagram
                size={30}
                color="#CA367A"
                className="cursor-pointer"
              />
              <FaFacebook
                size={30}
                color="#0573A0"
                className="cursor-pointer"
              />
              <BsWhatsapp
                size={30}
                color="#5BC61E"
                className="cursor-pointer"
              />
            </div>
          </ul>
        </Col>
        <Col lg={3} xs={4} className="justify-center">
          <li className="list-none text-Main text-2xl mb-2 font-bold w-fit text-center mx-auto">
            {content.TopAreas}
          </li>
          {data?.data.data.data.slice(0, 6).map((e) => (
            <>
              <NavLink
                to={
                  "/home/area/" +
                  e.id +
                  "?area_id=" +
                  e.id +
                  "&local=" +
                  localStorage.getItem("language")
                }
              >
                <li className="list-none hover:text-Main text-xl font-semibold w-fit text-center mx-auto">
                  {e.name}
                </li>
              </NavLink>
            </>
          ))}
        </Col>
        <Col lg={3} xs={4} className="justify-center">
          <li className="list-none text-Main text-2xl mb-2 font-bold w-fit text-center mx-auto">
            {content.Recommended}
          </li>
          {Recommended?.data.data.data.slice(0, 7).map((e) => (
            <li className="list-none hover:text-Main text-xl font-semibold w-fit text-center mx-auto">
              {e.name}
            </li>
          ))}
        </Col>
        <Col lg={3} xs={4} className="justify-center">
          <li className="list-none text-Main text-2xl mb-2 font-bold w-fit text-center mx-auto">
            {content.TopCompound}
          </li>
          {compounds?.data.data.data.slice(0, 7).map((e) => (
            <NavLink
              to={
                "/home/compound/" +
                e.id +
                "?compound_id=" +
                e.id +
                "&local=" +
                localStorage.getItem("language")
              }
            >
              <li className="list-none hover:text-Main text-xl font-semibold w-fit text-center mx-auto">
                {e.name}
              </li>
            </NavLink>
          ))}
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
