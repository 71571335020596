import React, { useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { useFilter } from "../APIs";
import Loading from "../Loading";
import { useContextTranslate } from "../../components/Translate/ContextProvider";

const Pagination = ({ pageCount, children, isLoading }) => {
  const { handlePageClick, searchParams } = useFilter();
  const { content } = useContextTranslate();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = searchParams.get("page")
        ? searchParams.get("page") - 1
        : 0;
    }
  }, [searchParams.get("page")]);
  return (
    <>
      {isLoading ? <Loading /> : ""}
      <div>{children}</div>
      {!pageCount ? (
        <div className="flex justify-center items-center text-2xl font-semibold h-[30vh]">
          {content.noData}
        </div>
      ) : (
        ""
      )}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
    </>
  );
};

export default Pagination;
